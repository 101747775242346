import moment from "moment";

import experience from "./experience";
import portfolio from "./portfolio";
// import hobbies from "./hobbies";
import other from "./contact";

const menuItems = {
  items: [
    experience,
    portfolio,
    // hobbies,
    other,
    {
      id: "copyright",
      title: `© 2022-${moment().format("YYYY")} by Shiva Velingker`,
      type: "group",
    },
  ],
};

export default menuItems;
