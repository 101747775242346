// assets
import {
  IconBriefcase,
  IconChecklist,
  IconHeartHandshake,
  IconSpeakerphone,
  IconTimeline,
} from "@tabler/icons";

const career = {
  id: "experience",
  title: "Experience",
  type: "group",
  children: [
    {
      id: "work",
      title: "Work Experience",
      type: "item",
      url: "/work",
      icon: IconBriefcase,
      breadcrumbs: false,
      color: "#ff3d00", // dark orange
    },
    {
      id: "impact",
      title: "Social Good",
      type: "item",
      url: "/impact",
      icon: IconHeartHandshake,
      breadcrumbs: false,
      color: "#f50057", // pink
    },
    {
      id: "testimonies",
      title: "Testimonials",
      type: "item",
      url: "/testimonies",
      icon: IconSpeakerphone,
      breadcrumbs: false,
      color: "#00a0b2", // dark cyan
    },
    {
      id: "resumes",
      title: "Resumes",
      type: "item",
      url: "/resumes",
      icon: IconChecklist,
      breadcrumbs: false,
      color: "#4caf50", // green
      visible: false,
    },
    {
      id: "timeline",
      title: "Timeline",
      type: "item",
      url: "/timeline",
      icon: IconTimeline,
      breadcrumbs: false,
      color: "#ff1744", // red
      visible: false,
    },
  ],
};

export default career;
