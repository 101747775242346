import _ from "lodash";
import moment from "moment";

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore/lite";

import ExperienceWrapper from "./ExperienceWrapper";
import TestimonyWrapper from "./TestimonyWrapper";
import Logger from "./logger";

// From Project Settings > General
const firebaseConfig = {
  apiKey: "AIzaSyCBPfqYZpTHog5PFgdCKTSG9wz2gVSrInY",
  authDomain: "shivalicious.firebaseapp.com",
  projectId: "shivalicious",
  storageBucket: "shivalicious.appspot.com",
  messagingSenderId: "1036387221086",
  appId: "1:1036387221086:web:db4d388edbea92c4a47ecf",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export default class Firestore {
  static async AnonymouslySignIn() {
    return await signInAnonymously(auth).then(() => {
      // Signed in..
      Logger.debug("Connected to DB");
    });
  }

  static Event(event, subtype) {
    const obj = {};
    obj[`${event}.${subtype}`] = increment(1);
    Firestore.Increment("events", obj);
  }

  static async Increment(document, data) {
    await Firestore.AnonymouslySignIn();
    const docRef = doc(db, "traffic", document);
    updateDoc(docRef, data);
  }

  static async fetchExperiences(category) {
    const experiences = collection(db, "experiences");
    let q = [experiences, where("visible", "!=", false)];
    if (category) {
      q.push(where("categories", "array-contains-any", [category]));
    }
    const rawDocs = (await getDocs(query(...q))).docs;
    return _.orderBy(
      _.map(rawDocs, (d) => new ExperienceWrapper(d.data())),
      [
        (d) => _.get(d, "ranking", 0),
        (d) => _.get(d, ["dates", "end"], moment().format("YYYY-MM-DD")),
      ],
      ["desc", "desc"]
    );
  }

  static async fetchHighlightedExperiences() {
    const experiences = await Firestore.fetchExperiences();
    return _.filter(experiences, (e) => e.highlighted);
  }

  static async fetchStoryboard(story) {
    const docRef = doc(db, "stories", story);
    return (await getDoc(docRef)).data();
  }

  static async fetchTestimonies() {
    const testimonies = collection(db, "testimonies");
    let filters = [];
    if (!Logger.mode().inProtectedMode) {
      filters.push(
        where("company", "!=", "Meta")
        // where("validated", "==", false)
      );
    }
    const q = query(testimonies, ...filters);
    const rawDocs = (await getDocs(q)).docs;
    const docs = _.map(rawDocs, (d) => new TestimonyWrapper(d.id, d.data()));
    return _.filter(docs, (d) => {
      return d.impact > 0 && d.manager === false;
    });
  }

  static getImage(folder, name, extension = "") {
    if (_.isNil(folder)) {
      return `https://firebasestorage.googleapis.com/v0/b/shivalicious.appspot.com/o/${name}${extension}?alt=media`;
    }
    return `https://firebasestorage.googleapis.com/v0/b/shivalicious.appspot.com/o/${folder}%2F${name}${extension}?alt=media`;
  }
}
