// assets
import {
  IconAt,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
} from "@tabler/icons";

const contact = {
  id: "contact",
  title: "Contact Me",
  type: "group",
  children: [
    {
      id: "email",
      title: "Email",
      type: "item",
      url: "mailto:info@shivalicious.com",
      icon: IconAt,
      external: true,
      color: "green",
    },
    {
      id: "linkedin",
      title: "LinkedIn",
      type: "item",
      url: "https://www.linkedin.com/in/shiva-velingker/",
      icon: IconBrandLinkedin,
      external: true,
      color: "blue",
    },
    {
      id: "instagram",
      title: "Instagram",
      type: "item",
      url: "https://www.instagram.com/shivalicious/",
      icon: IconBrandInstagram,
      external: true,
      color: "#e91e63", // pink
    },
    {
      id: "youtube",
      title: "YouTube Channel",
      type: "item",
      url: "https://www.youtube.com/shivalicious",
      icon: IconBrandYoutube,
      external: true,
      color: "red",
      visible: false,
    },
  ],
};

export default contact;
