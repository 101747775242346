import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "layout/Loadable";

// Page routing
const HomePage = Loadable(lazy(() => import("pages/home")));
const Experiences = Loadable(lazy(() => import("pages/experiences")));
const Testimonies = Loadable(lazy(() => import("pages/testimonies")));
const TexasVoterTracker = Loadable(
  lazy(() => import("pages/storyboards/texas_voter_tracker"))
);
const MadeToUplift = Loadable(
  lazy(() => import("pages/storyboards/made_to_uplift"))
);
const WIPPage = Loadable(lazy(() => import("pages/wip")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <HomePage />,
    },

    // CAREER
    {
      path: "work",
      element: <Experiences category="Work" title="Work Experience" />,
    },
    {
      path: "impact",
      element: (
        <Experiences
          category="Social Good"
          title="Social Good"
          subtitle="A sampling of my community engagement work, from volunteering to larger endeavors"
        />
      ),
    },
    {
      path: "testimonies",
      element: <Testimonies />,
    },
    {
      path: "resumes",
      element: <WIPPage />,
    },
    {
      path: "timeline",
      element: <WIPPage />,
    },

    // PORTFOLIO
    {
      path: "portfolio",
      element: (
        <Experiences
          category="Portfolio"
          title="Full Portfolio"
          subtitle="Passion projects makes me really happy :)"
        />
      ),
    },
    {
      path: "2020-tracker",
      element: (
        <TexasVoterTracker year={2020} subtitle="The Original Release" />
      ),
    },
    {
      path: "2022-tracker",
      element: <TexasVoterTracker year={2022} subtitle="The Latest Release" />,
    },
    {
      path: "2022-tracker",
      element: <WIPPage />,
    },
    {
      path: "made-to-uplift",
      element: <MadeToUplift />,
    },
    {
      path: "uplift-journal",
      element: <WIPPage />,
    },
    {
      path: "memories",
      element: <WIPPage />,
    },

    // HOBBIES
    {
      path: "alaska",
      element: <WIPPage />,
    },
    {
      path: "travel",
      element: <WIPPage />,
    },
    {
      path: "dance",
      element: <WIPPage />,
    },
    {
      path: "guitar",
      element: <WIPPage />,
    },
    {
      path: "quotes",
      element: <WIPPage />,
    },
  ],
};

export default MainRoutes;
