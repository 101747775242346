import _ from "lodash";

export default class Logger {
  static mode() {
    const currentUrl = _.toLower(window.location.href);
    return {
      inDebugMode:
        currentUrl.includes("localhost") || currentUrl.includes("test."),
      inProtectedMode:
        currentUrl.includes("stanford") ||
        currentUrl.includes("test.") ||
        currentUrl.includes("unfiltered."),
      allowEmail: currentUrl.includes("stanford"),
    };
  }

  static debug(msg) {
    if (Logger.mode().inDebugMode) {
      console.log(msg);
    }
  }

  static transparency(msg) {
    console.log(msg);
  }
}
