// material-ui
import { Avatar, Button } from "@mui/material";
import { useTheme, styled } from "@mui/styles";

import Firestore from "data/firestore";

const GradientText = styled("span")(() => ({
  background: "linear-gradient(90deg, #000000 0%, #7F8C8D 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontFamily: "Montserrat, sans-serif",
  fontSize: "2em",
  fontWeight: 400,
  textTransform: "uppercase",
  marginLeft: 5,
  marginRight: 5,
}));

const Logo = () => {
  const theme = useTheme();

  return (
    <Button
      endIcon={
        <Avatar
          alt="My pretty face"
          src={Firestore.getImage(null, "me", ".jpg")}
          style={{
            marginRight: 15,
            height: theme.spacing(7.5),
            width: theme.spacing(7.5),
          }}
        />
      }
      disableRipple
    >
      <GradientText>Shiva</GradientText>
    </Button>
  );
};

export default Logo;
